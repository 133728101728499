import Vue from 'vue'
import Vuex from 'vuex'
import { $api } from 'bh-mod'
import settings from './settings'
import {setProp, deleteProp,arrToObj} from 'bh-mod'
// setProp(state,['units',data.id],data)
let tld = window.location.href.includes('bildhive.com')? 'com' : 'dev'
let siteURL = window.location.href.includes('localhost') ? sessionStorage.siteURL || 'http://localhost:3001' : `https://srv2.bildhive.${tld}`
// let siteURL = window.location.href.includes('localhost') ? sessionStorage.siteURL || `https://srv2.bildhive.${tld}` : `https://srv2.bildhive.${tld}`
siteURL = `https://srv2.bildhive.${tld}`
Vue.use(Vuex)

export default {
    state: {
		updateFrame:0,
        tld,
        ctnURL: `https://ctn.bildhive.${tld}`,
        formList:{},
        pageSections:[],
        builder:{
            top:0,
            currentSection:'',
            currPageId:'',
            richVariables:false,
            variables:[],
            landing:false,
            currProject:{},
            currProjectId:'',
            pageChanging:false,
            live:true,
            previewModel:'lorem',
        },
        fontList:[],
        allSettings:{},
        filter:'',
        modelTags:{},
        tempEmail:{},
        projects:{},
        chooseColor:{
            show:false,
            data:{}
        },
        sectionModal:{
            init:false,
            show:false,
            cb:null,
            restriction:'none',
            replace:false
        },
        siteURL,
        sitePreviewURL:window.location.href.includes('localhost')? 'http://127.0.0.1:3000' : `https://preview.bildhive.${tld}`,
        cson:[],
        broadcastModal:{
            show:false,
            id:'',
        },
        emailVariable:{
            show:false,
            fields:[]
        },
        sections:{},
        editBroadcast:{},
        senders:[],
        loadingPage:false,
        newStoreTime:Date.now(),
        getStarted: false,
        templates:{},
        drafts:{},
        broadcasts:{},
        dom:{w:document.body.clientWidth,h:document.body.clientHeight},
        addContent:{
            show:false,
            page:{},
            isNew:false
        },
        templateDrawer: {
            show:false,
            type:'add',
            site:'',
            data:{}
        },
        modal:{
            show:false,
            title:'',
        },
        reorderSection:0

    },
    mutations: {
		UPDATE_FRAME(state){
			state.updateFrame = Date.now()
		},
        UPDATE_PROJECT(state,project){
            let newProjects = JSON.parse(JSON.stringify(state.projects))
            newProjects[project.id].image = project.image
            state.projects = newProjects
        },
        REORDER_SECTIONS(state){
            state.reorderSection = Date.now()
        },
        LIVE: (state, is = true) => {
            state.builder.live = is
        },
        SET_SETTINGS(state, data) {
            if (data.userApp == null) {
                data.userApp = {
                    options:{
                        seenIntro:false,
                    }
                }
            }
            state.allSettings = data
        },
        EMAIL_TYPE(state, data){
            state.filter = data.value
        },
        SET_SECTION: (state,section) => state.sections[section.id] = section,
        SET_SECTIONS: (state,sections) => state.sections = {...state.sections,...arrToObj(sections)},
        EDIT_BROADCAST(state,data){
            state.editBroadcast = data
        },
        CHOOSE_COLOR: (state,data) => {
            state.chooseColor = {
                show:true,
                type:data.type,
                color:data.color
            }
        },
        SET_EMAIL_VARIABLES: (state,data) => state.appDataVariable.fields = data,
        SHOW_EMAIL_VARIABLE: state => state.appDataVariable.show = true,
        CLOSE_EMAIL_VARIABLE: state => state.appDataVariable.show = false,
        SET_BROADCAST: (state,data) => state.broadcastModal = {show:true,data},
        CLOSE_BROADCAST: (state,data) => state.broadcastModal = {show:false,data:{}},
        VIEWPORT: (state,w) => state.dom = w,
        SHOW_ADD_CONTENT: (state,{page,isNew}) => state.addContent={show: true,page,isNew },
        CLOSE_ADD_CONTENT: state => state.addContent={show: false,page:{},isNew:false },
        SET_CSON: (state,cson) => state.cson = cson,
        LOAD_PAGE: (state,status = !state.loadingPage) => state.loadingPage = status,
        SET_PROP: (state,{where,what,del = false,update = false}) => {
            if (del) return deleteProp(state,where)
            if (typeof where === 'string') where = where.split('.')
            state.newStoreTime = Date.now()
            setProp(state,where,what)
        },
        CLOSE_DRAWERS: state => {
            state.templateDrawer = {
                show:false,
                type:'',
                id:'',
                data:null
            }
            state.broadcastModal = {show:false,data:{}}
        },
        CLOSE_MODALS: (state) => {
            state.statusModal = {type:'', show:false}
            state.formModal = {show:false,type:''}
            state.chooseColor = {show:false,type:'',color:''}
        },
        SHOW_ADD_TEMPLATE: (state)=> {
            state.templateDrawer = {
                show:true,
                type:'add',
                id:'',
                data:null
            }
        },
        SHOW_EDIT_TEMPLATE: (state,data)=> {
            state.templateDrawer = {
                show:true,
                type:'edit',
                id:'',
                data
            }
        },
        SET_APPDATA(state, data) {
            if (!data) data = {}
            let {projects= []} = data

            state.projects = arrToObj(projects)

        },
        GET_STARTED: state => {
            state.getStarted = true
        },
    },
    actions: {
        SET_APPDATA: ({commit},data) => commit('SET_APPDATA',data),

        MESSAGE: ({state},data) => {
            document.querySelector('iframe').contentWindow.postMessage(data, state.siteURL)
        }

    },
    getters:{
        previewModel: (state,getters) => {

            let previewModel = state.builder.previewModel
            return getters.previewModelList[previewModel]

        },
        previewModelList: state => {

            if (!state.builder.datas) return {}

            let {models = {}} = state.builder.datas
            let result = {
                lorem:{
                    label:'[Sample Model]',
                    value:'lorem',
                    unitGroup:{name:'The Loremipsum'},
                    unit:{name:'A'}
                }
            }
            Object.values(models).forEach(inst => {
                let {models:uGs = []} = inst
                uGs.forEach(uG => {

                    if (!uG.units || !uG.units.length) return

                    let unitGroupId = uG.id

                    uG.units.forEach(unit => {

                        let unitId = unit.id
                        let id = `${unitGroupId}-${unitId}`
                        result[id] = {
                            label:`${uG.name} ${unit.name}`,
                            value:id,
                            unitGroup:uG,
                            unit
                        }

                    })

                })

            })

            return result

        },
        currPage: state => state.builder.currProject.id ? state.builder.currProject.pages.find(x => x.id === state.builder.currPageId) : {},
        isLanding: state => {
            return state.builder.currProject.type === 'landing'
        },
        pagesOptions: state => {
            if (!state.builder.currProject.pages) return []
            let pages = state.builder.currProject.pages.filter(x => x.id !== state.builder.currPageId && !x.default)
            return pages.map( ({name,slug,id}) => ({label:name,value:slug,id}))
        },
        pagesOptionsId: state => {
            if (!state.builder.currProject.pages) return []
            let pages = state.builder.currProject.pages.filter(x => {
				if (state.builder.currProject.type == 'static') return !x.default && x.slug != 'modeldetails' && x.slug != 'blogpost' && x.slug != 'login'
				else return !x.default
			})
            return pages.map( ({name,slug,id}) => ({label:name,value:id}))
        },
        pageSections: (state,getters) => {
            let page = getters.currPage || {}
            let {sections,header,footer} = JSON.parse(JSON.stringify(page))
            let project = state.builder.currProject
            let sectionsArray = []

            if (project.headers && project.headers.length){
                sectionsArray = [...sectionsArray,...project.headers]
            } else if (header && header.id){
                sectionsArray.push(header)
            }

            if (sections && sections.length){
                sectionsArray = [...sectionsArray,...sections]
            }

            if (project.headers && project.headers.length){
                sectionsArray = [...sectionsArray,...project.footers]
            } else if (footer && footer.id){
                sectionsArray.push(footer)
            }

            return sectionsArray
        },
        pageSectionsOptions: (state,getters) => {
            return getters.pageSections.map(item => ({
                label:item.name,
                value:item.id
            }))
        },
    }
}
